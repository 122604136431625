import React from 'react'
import { useStaticQuery, graphql } from "gatsby";


export const GetURL = (id) => {
  const data = useStaticQuery(graphql`
    query {
	glstrapi {
		 allMenus(where: {Publish: true}) {
		   URL
		   Label
		   Template
		   Main_Parent {
		     Label
		     URL
		     Alias
		   }
		   Alias
		   Sub_Parent {
		     Alias
		     Label
		     URL
		   }
		   Article {
		     _id
		     Pagename
		   }
		   _id
		 }
	}
    }
  `);

	let PageURL = data.glstrapi.allMenus.filter(item => item._id === id).pop();


	let URL = decodeURI(encodeURIComponent(decodeURIComponent(PageURL?.URL)))

	if((PageURL?.Main_Parent != null) &&(PageURL.Sub_Parent == null)) {
    	URL = decodeURI(encodeURIComponent(decodeURIComponent(PageURL.Main_Parent.URL))) + '/' +URL
  	}

	else if((PageURL?.Main_Parent != null) &&(PageURL.Sub_Parent != null)) {
    	URL = decodeURI(encodeURIComponent(decodeURIComponent(PageURL.Main_Parent.URL))) + '/' + decodeURI(encodeURIComponent(decodeURIComponent(PageURL.Sub_Parent.URL))) + '/' +URL
  	}

    if(URL == "property/for-sale") {
        URL = URL+"/in-south-buckinghamshire"
    }
    if(URL == "property/to-rent") {
        URL = URL+"/in-south-buckinghamshire"
    }

    URL = URL+ '/';

	return URL;

}


export const NewsDate = (date) => {

	const d  = new Date(date);
	const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
	const mo = new Intl.DateTimeFormat('en', { month: 'long' }).format(d);
	const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
	return (
		<span>{`${da} ${mo} ${ye}`}</span>
	)
}

export const lazyLoader = (element, listener) => {
	var events = ['mousemove','touchmove','keypress'];
	for (var i=0, iLen=events.length; i<iLen; i++) {
	  element.addEventListener(events[i], listener, false);
	}
} 
