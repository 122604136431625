import React from 'react'
import { Button } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const ButtonField = ({type, name, value, fieldClass, grpmd}) => (
	<div className={`col-md-${grpmd} col-sm-12 col-xs-12`}>
		<Button variant="" type={type} className={fieldClass}>
			{value}
		</Button>
	</div>
);

export default ButtonField;