import Link from './link';
import { isEmpty, capitalize, join, startCase, toLower, find } from "lodash";

const METER_VAL = 1609.34;
/** string remove all special characters*/
const removeSpecialChar = (val, symbol = '-') => {
    if (!isEmpty(val)) {
        return val.replace(/[^\w\s]/gi, ' ').replace(/\s\s+/g, ' ').replace(/ /gi, symbol).toLowerCase()
    }
    return val;
}

const isAreaMatch = (areaList, area) => {
    return find(areaList, ['slug', removeSpecialChar(area)])
}
const getLatLan = (item) => {
    if (item?.latitude && item?.longitude) {
        return `${item.latitude},${item.longitude}`
    }
    return '';
}

const milesToMeter = (val) => {
    return Math.round(parseInt(val) * METER_VAL)
}


export {
    Link,
    isAreaMatch,
    removeSpecialChar,
    isEmpty,
    getLatLan,
    milesToMeter
}



