import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col"

const InputField = ({ fieldClass, labelClass, type, name, value, required, placeholder, handlechange, pattern, label, grpmd, onFocus, min }) => (
    <Form.Group as={Col} md={grpmd} controlId={"validation" + name}>
        {label ? <Form.Label className={labelClass}>{label}{required ? '*' : ''}</Form.Label>: ''}
        <Form.Control
            className={fieldClass}
            required={required}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={handlechange}
            pattern={pattern}
            onFocus={onFocus}
            min={min}
        />
    </Form.Group>
);

export default InputField;