import React, { useRef } from "react"
import { Link } from "@StarberryUtils"
import { useStaticQuery, graphql } from "gatsby";

import img from "../../images/logo.svg"
import OutsideAlerter from "../OutSideClick"
import "./Header.scss"

import { GetURL } from "../common/site/functions";

// Header component
const Header = (props) => {

    const data = useStaticQuery(graphql`
        query {
        glstrapi {
            siteConfig {
            Top_Navigation_Links {
                Label
                Link {
                _id
                }
                Add_Sub_Menus {
                    Sub_Menu_Label
                    Sub_Menu_Link {
                    _id
                    }
                }
            }
            }

            menuBurgers(where: {Publish: true}, sort: "Sort") {
                _id
                Parent_Label
                Parent_Link {
                _id
                }
                Sub_Menus {
                Label
                Link {
                    _id
                }
                Add_Sub_Menus {
                    Sub_Menu_Label
                    Sub_Menu_Link {
                    _id
                    }
                }
                }
            }
            areaGuides(where: {Publish: true}, sort: "Sort") {
                URL
                Title
                Tile_Image {
                  url
                  alternativeText
                }
                Embed_Video_URL
              }
            offices(sort: "Sort") {
                URL
                Title
                id
            }
            localLivings(where: {Publish: true}, sort: "Sort") {
                Name
                Sort
                URL
            }

        }
        }
    `);
    let hamburger = useRef(null); // creating hamburger ref
    let primaryHeader = useRef(null); // Creating ref for hamburger

    // adding and removing class from main nav
    const handleHamBurgerClick = () => {
        if (Array.from(hamburger.current.classList).includes("open-navigation")) {
            primaryHeader.current.classList.remove("open-navigation");
            document.body.className = '';

        } else {
            primaryHeader.current.classList.add("open-navigation")
            document.body.className = '';

        }
        if (Array.from(hamburger.current.classList).includes("cross")) {
            hamburger.current.classList.remove("cross")
            primaryHeader.current.classList.remove("open-navigation")
            document.body.className = '';
        } else {
            hamburger.current.classList.add("cross")
            //document.body.className += 'overflow-hidden';
        }
    }

    // Out side click remove class
    const handleOutSideClick = e => {
        primaryHeader.current.classList.remove("open-navigation");
        let cross = document.getElementsByClassName("cross");
        if (cross && cross.length > 0) {
            setTimeout(() => { cross[0]?.classList?.remove("cross") }, 200)
            setTimeout(() => { document.body.className = ''; }, 200)
        }
    }

    // function for toggle between + and - icons in nav Menu
    const toggleNav = e => {
        try {
            let isClassFound = e.target.classList.contains("icon-minus")
            if (!isClassFound) {
                let dom = document.getElementsByClassName("icon-vector icon-minus")
                let domSub = document.getElementsByClassName("sub-menu parent")
                let subNav = document.getElementsByClassName("navchild")
                let childIcons = document.getElementsByClassName("childicon")
                for (let element of subNav) {
                    element.style.display = "none"
                }
                for (let element of domSub) {
                    element.style.display = "none"
                }
                for (let element of dom) {
                    element.classList.remove("icon-minus")
                }
                for (let element of childIcons) {
                    element.classList.remove("icon-minus")
                    element.classList.add("icon-vector")
                }
                e.target.classList.add("icon-minus")
                e.target.parentNode.nextSibling.style.display = "block"
                dom = null
            } else {
                e.target.classList.remove("icon-minus")
                let dom = document.getElementsByClassName("sub-menu parent")
                let subChild = document.getElementsByClassName("sub-menu child")
                for (let element of subChild) {
                    element.style.display = "block"
                }

                for (let element of dom) {
                    element.style.display = "none"
                }
                e.target.parentNode.nextSibling.style.display = "none"
            }
        } catch (e) {
            console.log("having some error in Toogle function", e)
        }
    }
    // function for toggle + and - in sub navigation
    const toggleNavChild = e => {
        let isClassFound = e.target.classList.contains("icon-minus");
        let subNav = document.getElementsByClassName("navchild");
        let subMenu = document.getElementsByClassName("childicon");
        let subMenuChild = document.getElementsByClassName("navchild");
        if (!isClassFound) {
            for (let element of subNav) {
                element.style.display = "block"
            }
            for (let element of subMenuChild) {
                element.style.display = "none"
            }

            for (let element of subMenu) {
                element.classList.remove("icon-minus")
            }
            e.target.classList.add("icon-minus");
            e.target.parentNode.nextSibling.style.display = "block"
        } else {
            for (let element of subNav) {
                element.style.display = "none"
            }

            e.target.classList.remove("icon-minus")
        }
    }
    return (
        <header className={`primary-header ${props.page == 'Home' ? '' : 'heading-top-bg'}`} ref={primaryHeader}>
            <div className="logo">
                <Link to="/"> <img src={img} alt="img" className="position-relative" /></Link>
            </div>
            <nav className="nav-bar">
                <ul className="nav-menu">
                    {data.glstrapi.siteConfig.Top_Navigation_Links.map((link, i) => {
                        let url = GetURL(link.Link._id)
                        return (
                            <>
                                {link?.Label === "Property Search" ?
                                    <>
                                        <li key={i} className="dropdown-top position-relative">
                                            <Link to={`/${url}`}>{link.Label}</Link>
                                            {link?.Add_Sub_Menus &&
                                                <div className="sub-menu-top" >
                                                    {link?.Add_Sub_Menus.map((subLink, k) => {
                                                        let subUrl = GetURL(subLink?.Sub_Menu_Link?._id)
                                                        return (
                                                            <Link to={`/${subUrl}`} key={k}>{subLink?.Sub_Menu_Label}</Link>
                                                        )
                                                    }
                                                    )}
                                                </div>
                                            }
                                        </li>
                                    </> :

                                    link?.Add_Sub_Menus.length > 0 ?
                                        <li key={i} className="dropdown-top position-relative">
                                            <Link to={`/${url}`}>{link.Label}</Link>
                                            {link?.Add_Sub_Menus &&
                                                <div className="sub-menu-top" >
                                                    {link?.Add_Sub_Menus.map((subLink, k) => {
                                                        let subUrl = GetURL(subLink?.Sub_Menu_Link?._id)
                                                        return (
                                                            <Link to={`/${subUrl}`} key={k}>{subLink?.Sub_Menu_Label}</Link>
                                                        )
                                                    }
                                                    )}
                                                </div>
                                            }
                                        </li>
                                        :
                                        <li key={i}>
                                            <Link to={`/${url}`}>{link?.Label}</Link>
                                        </li>
                                }
                            </>
                        )
                    })}
                </ul>
            </nav>
            <div className="menu-icons">
                <a href="tel:01494 677 733" className="d-md-none mr-2 d-block"><i className="icon-call d-md-none"></i></a>
                {/* <Link href="#">
                <i className="icon-account"></i>
                </Link> */}
                <button
                    className="hamburger "
                    ref={hamburger}
                    onClick={handleHamBurgerClick}
                >
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>


            <div className="nav-wrap">
                <OutsideAlerter handleSubRoutine={handleOutSideClick}>
                    <nav className="navigation">
                        <div className="hamburger-wrap">
                            <button className="hamburger hamburger-cross" onClick={handleHamBurgerClick}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                        <ul className="nav-item">
                            {data.glstrapi.siteConfig.Top_Navigation_Links.map((link, i) => {
                                let url = GetURL(link.Link._id)
                                return (
                                    <li className="d-md-none" key={i}>
                                        <Link to={`/${url}`}>{link.Label}</Link>
                                    </li>
                                )
                            })}

                            {data.glstrapi.menuBurgers.map((menu, i) => {
                                let url = GetURL(menu.Parent_Link._id)
                                return (
                                    <li key={i}>
                                        {menu?.Sub_Menus ?
                                            <>
                                                {(url == "about" || url == "rent") ? <a onClick={() => document.getElementById(`icon-vector+${i}`).click()} href="javascript:;">{menu.Parent_Label}</a> : <Link to={`/${url}`}>{menu.Parent_Label}</Link>}
                                                <a href="javascript:;" className="icon-vector-ct"><i id={`icon-vector+${i}`} className="icon-vector" onClick={toggleNav} onKeyDown={toggleNav} aria-label="buy"></i></a>
                                                <ul className="sub-menu parent">
                                                    {menu?.Sub_Menus.map((link, k) => {
                                                        let sub_url = GetURL(link?.Link?._id && link?.Link?._id)

                                                        // console.log ("zzzzzzz", sub_url)
                                                        
                                                        return (
                                                            <>
                                                                {link?.Add_Sub_Menus?.length !== 0 ?
                                                                    <li className="local-living" key={k}>
                                                                        <a>
                                                                            <Link to={`/${sub_url}`}>
                                                                                {link.Label}
                                                                            </Link>
                                                                            <i className="icon-vector childicon" onClick={toggleNavChild}
                                                                                onKeyDown={toggleNavChild} aria-label="buy"
                                                                            ></i>
                                                                        </a>
                                                                        {sub_url.includes("offices") &&
                                                                            <ul className="sub-menu navchild pl-3">
                                                                                {
                                                                                    data.glstrapi.offices.map((office, a) =>
                                                                                        <li key={a}>
                                                                                            <Link to={`/offices/${office?.URL}`}>{office?.Title}</Link>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            </ul>
                                                                        }
                                                                        {!sub_url.includes("offices") &&
                                                                            <ul className="sub-menu navchild pl-3">
                                                                                {
                                                                                    data.glstrapi.localLivings.map((living, a) =>
                                                                                        <li key={a}>
                                                                                            <Link to={`/about/local-living/${living.URL}`}>{living.Name}</Link>
                                                                                        </li>
                                                                                    )
                                                                                }
                                                                            </ul>

                                                                        }

                                                                    </li>
                                                                    :
                                                                    <li className="our-team" key={k}>
                                                                        <Link to={`/${sub_url}`}>{link.Label}
                                                                        </Link>
                                                                    </li>
                                                                }
                                                            </>
                                                        )
                                                    })}
                                                </ul>
                                            </> :
                                            <li><Link href={`/${url}`}>{menu.Parent_Label}</Link></li>
                                        }
                                    </li>
                                )
                            })}

                        </ul>
                    </nav>
                </OutsideAlerter>
            </div>
            <div className="fixed-header" />
        </header>
    )
}

export { Header }
