import React from "react";
import { Link } from "@StarberryUtils"
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Modal } from "react-bootstrap";
//import CookieConsent from "react-cookie-consent";
import CookieConsent from "../CookieConsent/CookieConsent";
// import img from '../../images/footer-logo.png';
import img2 from '../../images/desktop-logo.png';
import './Footer.scss';
import { GetURL } from "../common/site/functions";
import starBerryLogo from "../../images/starberry-logo.png"
import HTMLReactParser from 'html-react-parser';
import ContactUsForm from "../forms/contact-us-form";
import img from "../../images/logo-white.svg"
import youtubeImg from "../../images/youtube.png";

const Footer = (props) => {
    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                siteConfig {
                    Facebook_Link
                    Instagram_Link
                    Twitter_Link
                    Youtube_Channel_Link
                    Footer_Bottom_Links {
                        Label
                        _id
                        Link {
                            _id
                            URL
                        }
                    }
                    Popular_Search_About
                    Popular_Search_Contact
                    Popular_Search_Rent
                    Popular_Search_Sales
                    Popular_Search_Static
                    Footer_Logo_Component {
                        Link
                        Name
                        Image {
                          url
                          alternativeText
                        }
                    }
                }
                offices {
                    Email
                    Address
                    Title
                    URL
                    Mobile
                    Office_Hours {
                        Office_Timings {
                            Timing
                        }
                    }
                }
            }
        }
    `);

    const [popularSearch, showPopularSearch] = React.useState(false);
    const [showForm, setShowForm] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const year = new Date().getFullYear();
    const offices = data.glstrapi.offices;
    
    return (
        <section className="footer">
            <CookieConsent />
            <Container className="mb-5 d-block popular-search">
                {props.search && <Row className={`px-3 heading ${popularSearch ? "border-0" : ""}`}>
                    <h4 onClick={() => showPopularSearch(!popularSearch)}>Popular Search {popularSearch ? '-' : '+'}</h4>
                </Row>}
                {popularSearch && 
                    <div className="justify-content-center popular-search-content pb-5">
                        {HTMLReactParser(data.glstrapi.siteConfig[props.search])}
                    </div>
                }
            </Container>
            <Container>
                <div className="details">
                    <Link  to="/" className="footer-logo">
                        <picture>
                        <source media="(min-width:1200px)" srcSet={img} alt="banner" />
                            <img src={img} alt="img" />
                        </picture>
                    </Link>
                    <div className="d-md-flex flex-row">
                        <div className="mt-4">
                        <p><strong>Beaconsfield</strong></p>
                            <address>
                                7/8 The Arcade, Maxwell Road
                                Beaconsfield HP9 1QT
                            </address>
                            <a href="tel:+01494677733" className="tel">01494 677 733</a>
                            <a  onClick={() => { setEmail("beaconsfield@bovingdons.com"); setShowForm(true); }} className="mail">beaconsfield@bovingdons.com</a>
                        </div>
                        {offices.filter(c => c.URL === "windsor").map((each, i) => 
                            <div key={i} className="mt-4 pl-md-4">
                                <p><strong>{each.Title}</strong></p>
                                <address className="mt-0">
                                    {HTMLReactParser(each.Address.replace(/\n/g, '<br />'))}
                               </address>
                                <a href={`tel:${each.Mobile}`} className="tel">{each.Mobile}</a>
                                <a onClick={() => { setEmail(each.Email); setShowForm(true); }} className="mail">{each.Email}</a>
                            </div>
                        )}
                    </div>
                </div>
                <div className="footer-descripton">
                    {data.glstrapi.siteConfig.Footer_Logo_Component && data.glstrapi.siteConfig.Footer_Logo_Component.length > 0 && (
                        <div className="our-brands">
                            {data.glstrapi.siteConfig.Footer_Logo_Component.map(item => {
                                return (
                                    <a href={item.Link} target="_blank">
                                        <img src={item.Image.url} alt={item.Name} />
                                    </a>
                                )
                            })}
                        </div>
                    )}
                    <div className="social-icons mb-0">
                        {data.glstrapi.siteConfig.Instagram_Link && <a href={data.glstrapi.siteConfig.Instagram_Link} target="_blank" className="icons">
                            <i className="icon-insta"></i>
                        </a>}
                        {data.glstrapi.siteConfig.Facebook_Link && <a href={data.glstrapi.siteConfig.Facebook_Link} target="_blank" className="icons fb">
                            <i className="icon-fb"></i>
                        </a>}
                        {data.glstrapi.siteConfig.Twitter_Link && <a href={data.glstrapi.siteConfig.Twitter_Link} target="_blank" className="icons">
                            <i className="icon-twitter"> </i>
                        </a>}
                    </div>
                    {data.glstrapi.siteConfig.Instagram_Link && 
                        <a className="btn follow my-4" href={data.glstrapi.siteConfig.Youtube_Channel_Link} target="_blank">
                            <img src={youtubeImg} className="mr-2 you-sub pb-1"/>
                            <span>subscribe</span>
                        </a>
                    }
                    <div className="footer-content">
                            {data.glstrapi.siteConfig.Footer_Bottom_Links.map((each, i) => 
                                <span className="terms-condition"  key={i}>
                                    {GetURL(each?.Link?._id)?.includes("http") ?
                                    <span>
                                        <a target="_blank" href={each.Link.URL}>{each.Label}</a>
                                        { i < data.glstrapi.siteConfig.Footer_Bottom_Links.length-1 ? " | " : " "}
                                    </span>
                                    :
                                    <span>
                                        <Link to={`/${GetURL(each.Link._id)}`}>{each.Label}</Link>
                                        { i < data.glstrapi.siteConfig.Footer_Bottom_Links.length-1 ? " | " : " "}
                                    </span>
                                }
                                </span>
                            )}
                            <span className="terms-condition">
                              <a href="javascript:void(0);" id="open_preferences_center"> |  Update cookies preferences</a>
                          </span>
                        <span className="copy-right">
                            <p className="m-0">© Copyright {year} Bovingdons</p>
                        </span>
                        <span className="site">Site by <a href="https://starberry.tv/" target="_blank"><img src={starBerryLogo} width={20} className="mr-1 pb-1"/>Starberry</a></span>
                    </div>
                </div>
            </Container>
            {/* <CookieConsent
                disableStyles={true}
                buttonClasses="btn btn-primary"
                containerClasses="cookie-popup d-lg-flex d-block"
                buttonWrapperClasses="align-self-center"
                buttonClasses="btn btn-link gotit"
                buttonText="Accept Cookies"

            >
                We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to="/cookie-policy">Cookie Policy</Link>.
            </CookieConsent> */}
            <Modal show={showForm} onHide={() => setShowForm(false)} className="enquire-form contact-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title className="px-0">Contact us</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ContactUsForm email={email}/>
                </Modal.Body>
            </Modal>
        </section>
    )
}
export { Footer };




